import { loadPartnerConfig } from '@/utils/theme';
import { loadRequest } from '@/request';
import { loadProductFeatureFlags } from '@/productFeatureFlags';
import { emitToParent } from '@/services/widgetApiEvents';
import getGlobalThemeId from './utils/getGlobalThemeId';

try {
  window.request = await loadRequest();
  window.featureFlags = loadProductFeatureFlags();

  const partnerConfig = await loadPartnerConfig(getGlobalThemeId() || window.request.themeId);
  if (partnerConfig.redesign) {
    import('@/main.v2');
  } else {
    import('@/main');
  }
} catch (e) {
  window.dispatchEvent(new Event('loading-status-error'));
  emitToParent('state', {
    state: 'loaded',
  });
  if (!e.message.includes('404')) {
    throw e;
  }
}
